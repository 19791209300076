
import { getPropertyAddress } from "~/helpers/property-helpers.js";

export default {
  inheritAttrs: false,

  props: {
    valuationProperties: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      headers: [
        { name: this.$t("CO_ASSESSMENT_TABLE_CADASTRAL"), active: true },
        { name: this.$t("CO_ASSESSMENT_TABLE_BFE"), active: false },
        { name: this.$t("ADDRESS"), active: false },
        { name: this.$t("CO_ASSESSMENT_TABLE_USAGE"), active: false },
        { name: this.$t("CO_ASSESSMENT_TABLE_OWNER"), active: false },
      ],
    };
  },

  computed: {
    filteredValuationProperties() {
      return this.valuationProperties.filter((x) => x.bfeNumber != this.$route.params.id);
    },
  },

  methods: {
    getAllCadastrals(valuationProperty) {
      return valuationProperty.plots?.map((x) => x.matrikelNumber)?.join(", ");
    },

    getAllProperties(properties) {
      return properties.plots?.map((x) => x.ownersGuildName)?.join(", ");
    },

    getAddress(property) {
      return getPropertyAddress(property);
    },

    getAllOwners(owner) {
      return owner.owners?.map((x) => x?.name)?.join(", ");
    },

    getAllUsageTexts(usageText) {
      let aggregatedUsageTexts = {};
      usageText.valuations.forEach((x) => {
        aggregatedUsageTexts[x.usageText] = aggregatedUsageTexts[x.usageText] ? aggregatedUsageTexts[x.usageText] + 1 : 1;
      });

      return Object.entries(aggregatedUsageTexts)
        ?.map((x) => `${x[0]}`)
        ?.join(", ");
    },
  },
};
